import React from "react"
import { components } from "react-select"

import "./style.scss"
import NewCustomSelect from "../NewCustomSelect"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const BrandSelect = ({ value, onChange, options, error, show, isDisabled, brand }) => {
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  const defaultOption = (optionsList, value) => {
    if (!optionsList || (!value && !show)) return ""

    return optionsList
      .reduce((result, { options }) => [...result, ...options], [])
      .find(({ id }) => id === value)
  }

  const filteredByBrandOptions = () => {
    const currentItem = ({ value }) => value === brand;
    const otherItems = ({ value }) => value !== brand;
    const hubbleFilter =  ({ value }) => ["classic", "hydro", "skyhy", "skyhy-monthly"].includes(value);
    const ccOtherLinkFilter = ({ id }) => id === -1;

    const hubbleOptions = options[0].options.filter(option => hubbleFilter(option) && !currentItem(option));
    const ccOtherLink = options[0].options.filter(ccOtherLinkFilter);

    const currentOption = options[0].options.filter(currentItem);
    const newRxOtherOptions = options[0].options.filter(option => otherItems(option) && !hubbleFilter(option) && !ccOtherLinkFilter(option))
    if (currentOption[0]) {
      currentOption[0] = {
        ...currentOption[0],
        current: true,
      }
    }
    newRxOtherOptions.forEach(item => (item.current = false))
    const otherOptions = [...hubbleOptions, ...(newRxOtherOptions), ...ccOtherLink]

    return [...currentOption, ...otherOptions]
  };
  const filteredOptions = filteredByBrandOptions()

  return (
    <NewCustomSelect
      name="brand"
      value={defaultOption(options, value)}
      onChange={onChange}
      options={filteredOptions}
      placeholder={isNewRx && !isNewRxDesktop ? "Select Brand" : "Select Your Brand"}
      components={{ Menu, Option, SingleValue }}
      getOptionValue={option => option.id}
      isDisabled={isDisabled}
    />
  )
}

const Menu = props => (
  <components.Menu {...props}>{props.children}</components.Menu>
)

const Option = props => {
  const { title, title2, price, price2, current, sale_price , cadence, value } = props.data
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const supply = cadence ? `(${(cadence / 28).toFixed(0)}-month supply)` : null

  return (
    <components.Option {...props}>
      <span className="text h8 neutral ocean-blue">
        {title2 ? title2 : title} {price !== "" ? <> - <b> ${price2 ? parseFloat(price2).toFixed(2) : parseFloat(sale_price || price).toFixed(2)}</b> </> : ""}
        {value === "skyhy-monthly" && <span>{supply}</span>}
        {!isNewRxDesktop && <br/>}
        <span className={`text h9 neutral currently-viewing-text ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>{((isNewRx || isNewRxDesktop) && current) && "(currently viewing)"}</span>
      </span>
    </components.Option>
  )
}

const SingleValue = props => {
  const { title, title2, price, price2, sale_price } = props.data
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  return <components.SingleValue {...props}>{title2 ? title2 : title} {((isNewRx || isNewRxDesktop) && price !== "") ? <> - <b> ${price2 ? parseFloat(price2).toFixed(2) : parseFloat(sale_price || price).toFixed(2)}</b> </> : ""}</components.SingleValue>
}

export default BrandSelect
