import "slick-carousel/slick/slick.css"
import SEO from "../components/seo"
import Layout from "../components/layout"
import React, { useContext, useEffect, useState } from "react"
import FAQBlock from "../components/FAQBlock"
import { subscriptionQuestions } from "../components/FAQ/faqs"
import RelatedFrames from "../components/RelatedFrames"
import { contactsRelatedProducts, hubbleValueProps } from "../services/data"
import VideoModule from "../components/VideoModule"
import HowHubbleWorksCarousel from "../components/HowHubbleWorksCarousel"
import ProductValueProps from "../components/ProductValueProps"
import Breadcrumbs from "../components/Breadcrumbs"
import AboutProductSection from "../components/AboutProductSection"
import "../styles/templates/avg-product.scss"
import CartContext from "../context/cartContext"
import AvgProductTopSection from "../components/AvgProductTopSection"
import { useWindowWidth } from "../services/hooks"

const AvgProduct = ({ pageContext }) => {
  const { setHeaderColor, cartItems } = useContext(CartContext)
  const { title, bulletPoints, options, description, tagline, metaDescription, heading} = pageContext
  const defaultOptionIndex = typeof window !== 'undefined'
    ? Math.max(0, options.findIndex(e => e.quantity === Number(window.location?.hash.replace("#", ""))))
    : 0;
  const [currentOptionIndex, setCurrentOptionIndex] = useState(Number(defaultOptionIndex))
  const width = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    let cartOptionId = options.findIndex(e => e.id === cartItems.find(e => e.isAvg)?.id)
    if (cartOptionId < 0) cartOptionId = undefined

    if (cartOptionId)
      setCurrentOptionIndex(cartOptionId)
  }, [cartItems]);

  useEffect(() => {
    if (width <= 1110 && !isMobile) setIsMobile(true)
    else if (width > 1110 && isMobile) setIsMobile(false)
  }, [width])

  useEffect(() => {
    setHeaderColor("purple")
    let windowScrollTimeout = setTimeout(() => {
      window.scrollTo(0, 0)
      clearTimeout(windowScrollTimeout)
    }, 1)

  }, [])

  return (
    <Layout className="avg-product">
      <SEO
        title={`${title} - Hubble Contacts`}
        description={metaDescription}
      />
      <AvgProductTopSection
        item={pageContext}
        currentOptionIndex={currentOptionIndex}
        setCurrentOptionIndex={setCurrentOptionIndex}
      />
      <ProductValueProps props={hubbleValueProps(true)} type="contacts" />
      <div className="product-info">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "..." },
              { to: "/contact-lenses", label: "All Contact Lenses" },
              { label: title },
            ]}
          />
        </div>
        {!isMobile && (
          <AboutProductSection
            title={title}
            type="contacts"
            heading={heading}
            description={tagline}
            bullet_points={bulletPoints}
          />
        )}
        <HowHubbleWorksCarousel color="purple" />
        <VideoModule
          type="contacts"
          video="https://www.youtube.com/embed/quoNFn7dxTw"
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
        {isMobile && (
          <AboutProductSection
            title={title}
            type="contacts"
            heading={heading}
            description={tagline}
            bullet_points={bulletPoints}
          />
        )}
        <FAQBlock faqList={subscriptionQuestions} />
      </div>
      <RelatedFrames
        relatedFrames={contactsRelatedProducts}
        type="contacts"
        linkTo="/contact-lenses"
      />
    </Layout>
  )
}

export default AvgProduct
