import React from "react"

import "./style.scss"

export const EnterRXWrapper = ({
  color,
  type,
  title,
  description,
  children,
  isDisabled,
}) => (
  <div
    className="enter-rx-wrapper"
    data-type={type}
    data-color={color}
    data-disabled={isDisabled}
  >
    <div className="wrapper-head">
      <h5 className="text h5">{title}</h5>
      {description && <p className="text h7">{description}</p>}
    </div>
    <div className="wrapper-body">{children}</div>
  </div>
)

export const RadioOption = ({
  color,
  value,
  title,
  description,
  price,
  active,
  setActive,
  isDisabled
}) => (
  <label
    htmlFor={value}
    className="radio-option"
    aria-checked={active === value}
    data-color={color}
    data-disabled={value === "high-index" && isDisabled}
  >
    <input
      id={value}
      type="radio"
      value={value}
      onClick={() => setActive(value)}
    />
    <span className="circle-icon" />
    <div className="text-block">
      <div className="heading">
        <h5 className="title text h5">{title}</h5>
        {price && <span className="price text h7 neue">+${price}</span>}
      </div>
      <p
        className="description text h10"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </label>
)
