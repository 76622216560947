import {Modal} from "react-bootstrap";
import React from "react";
import "./style.scss"

const NoProductModal = ({show, onHide}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="no-product-modal-dialog"
      className="no-product-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Oh no!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal_wrapper'>
          <p>
            Looks like your prescription has been discontinued by the manufacturer.
            <br/><br/>
            Please reach out to us at <a href="mailto:rxhelp@hubblecontacts.com">rxhelp@hubblecontacts.com</a> and we'll help get you into a new lens!
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NoProductModal;