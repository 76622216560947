import React from "react"
import { components } from "react-select"

import "./style.scss"
import NewCustomSelect from "../NewCustomSelect"

const FindDoctorSelect = ({
  doctor,
  onChange,
  options,
  isDisabled,
  isLoading,
  error,
}) => {
  return (
    <NewCustomSelect
      name="doctor"
      value={doctor}
      onChange={onChange}
      options={options}
      placeholder="Search by doctor, clinic, zip code or address"
      error={error}
      isDisabled={isDisabled || isLoading}
      isLoading={isLoading}
      components={{ Option, SingleValue }}
      getOptionValue={option => option.id}
      filterOption={({ data }, input) => {
        const { clinic_name, name, postcode, address_1 } = data

        if (!input) {
          return true
        }

        return name?.toLowerCase().startsWith(input?.toLowerCase()) ||
               clinic_name?.toLowerCase().startsWith(input?.toLowerCase()) ||
               address_1?.toLowerCase().startsWith(input?.toLowerCase()) ||
               postcode?.toLowerCase().startsWith(input?.toLowerCase())
      }}
    />
  )
}

const Option = props => {
  const { name, clinic_name, address_1, city, state, postcode } = props.data
  const address = `${address_1}, ${city}, ${state} ${postcode}`

  return (
    <components.Option {...props}>
      <div className="name-block text h8 neutral ocean-blue">{name}{name === "" && clinic_name}</div>
      <div className="clinic-block text h8 neutral ocean-blue">
        {clinic_name}
      </div>
      <div className="address-block text h8 neutral ocean-blue">{address}</div>
    </components.Option>
  )
}

const SingleValue = props => {
  const { name, clinic_name } = props.data

  return (
    <components.SingleValue {...props}>
      <span className="text h8 neutral">{name ? name : clinic_name}</span>
      <span className="text h8 neutral">{name ? clinic_name : ""}</span>
    </components.SingleValue>
  )
}

export default FindDoctorSelect
