import React from "react"

import "./style.scss"

const RequiredMessage = ({className, requiredText}) => {
  return (
    <div className={`error-block ${className}`}>*{requiredText || "This field is required"}</div>
  )
}

export default RequiredMessage