import React, {useState, useEffect, useContext} from "react"

import "./style.scss"
import PrescriptionInput from "../PrescriptionInput"
import RequiredMessage from "../RequiredMessage"
import cartContext from "../../context/cartContext";

const CantFindDoctor = ({ show, toggleShow, isDisabled, isEmptyValues, setDoctor }) => {
  const {order} = useContext(cartContext)
  const [doctor, setFormDoctor] = useState({
    name: "",
    address: "",
    clinicName: "",
    phone: "",
    city: "",
    ...(order?.doctor?.isNew && order?.doctor),
    isNew: true,
  })

  useEffect(() => {
    if (!show) return

    // Check if there are empty fields
    if (Object.values(doctor).some(value => !value)) {
      return
    }

    setDoctor(doctor)
  }, [show, doctor])

  const handleChangeDoctor = ({ target: { name, value } }) => {
    setFormDoctor(prev => ({ ...prev, [name]: value }))
  }

  return (
    <div className="cant-find-doctor-section">
      <label
        className="doctor-radio"
        htmlFor="cant-find-doctor"
        aria-disabled={isDisabled}
        aria-checked={show}
      >
        <span className="round-icon" />
        <input
          disabled={isDisabled}
          type="radio"
          id="cant-find-doctor"
          onClick={toggleShow}
        />
        <span className="text h8 neutral title">Can’t find your doctor?</span>
      </label>
      {show && (
        <div className="form-container">
          <PrescriptionInput
            name="name"
            label="Doctor’s name"
            placeholder="Enter doctor’s name"
            value={doctor.name}
            onChange={handleChangeDoctor}
          />
          {!doctor.name && isEmptyValues && <RequiredMessage/>}
          <PrescriptionInput
            name="clinicName"
            label="Clinic name"
            placeholder="Enter clinic name"
            value={doctor.clinicName}
            onChange={handleChangeDoctor}
          />
          {!doctor.clinicName && isEmptyValues && <RequiredMessage/>}
          <PrescriptionInput
            name="phone"
            label="Phone"
            placeholder="Enter phone"
            value={doctor.phone}
            onChange={handleChangeDoctor}
          />
          {!doctor.phone && isEmptyValues && <RequiredMessage/>}
          <PrescriptionInput
            name="address"
            label="Address"
            placeholder="Enter address"
            value={doctor.address}
            onChange={handleChangeDoctor}
          />
          {!doctor.address && isEmptyValues && <RequiredMessage/>}
          <PrescriptionInput
            name="city"
            label="City"
            placeholder="Enter city"
            value={doctor.city}
            onChange={handleChangeDoctor}
          />
          {!doctor.city && isEmptyValues && <RequiredMessage/>}
        </div>
      )}
    </div>
  )
}

export default CantFindDoctor
