import React from "react"
import RequiredMessage from "../RequiredMessage"

import "./style.scss"

const PrescriptionInput = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  type,
  name,
  isDisabled,
  isValue,
  showMessage,
  isRequiredInput,
  isEmail,
  isValidEmail
}) => {
  return (
    <div className="prescription-input">
      {label && <p className="title text h8 bulky ocean-blue">{label}</p>}
      <input
        type={type}
        name={name}
        className="text h8 neutral"
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
      />
      {isRequiredInput && (!isValue && showMessage) && <RequiredMessage />}
      {isEmail && (isValue && showMessage && !isValidEmail) && <RequiredMessage requiredText={"Invalid email address"}/>}
    </div>
  )
}

export default PrescriptionInput
