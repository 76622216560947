import React from "react"

import "./style.scss"
import Modal from "react-bootstrap/Modal"

const PowerConfirmationModal = ({ modalShow, setModalShow }) => {
  return (
    <Modal 
      show={modalShow} 
      onHide={() => setModalShow(false)} 
      className="pd-modal modal_wrapper"
    >
      <Modal.Header closeButton />
      <Modal.Body>
      <div className='heading-title'>
        <h3>Positive and negative powers selected</h3>
      </div>
        <p className="text">Make sure you have selected the correct values.</p>
        <button className="btn stay-btn" onClick={() => setModalShow(false)}>It is correct</button>
      </Modal.Body>
    </Modal>
  )
}

export default PowerConfirmationModal
